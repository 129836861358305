<template>
  <div>
    <div v-for="(item, index) in data.ProgressIndicator" :key="index">
      <i :class="$h.arrToObject(item.icon.split(' '))"></i>
      {{ $t.translate(item.label) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
};
</script>
