<template>
  <div>
    <div class="header pb-5 pt-7">
      <div class="container-fluid">
        <el-collapse class="expand-main mb-4 shadow">
          <el-collapse-item class="expand-item">
            <template #title>
              <div class="row pl-3">
                <div class="col-12 d-flex align-items-center">
                  <i class="fas fa-search"></i>
                  <h3 class="mb-0 ml-2">{{ $t.translate("LBL_SEARCH") }}</h3>
                </div>
              </div>
            </template>
            <card shadow type="secondary" class="">
              <div class="">
                <div class="row mb-3">
                  <div class="col-lg-6 form-group">
                    <label class="form-control-label">{{
                      $t.translate("LBL_LAST_UPDATE")
                    }}</label>

                    <el-date-picker
                      v-model="Filter.DatePeriod"
                      type="daterange"
                      class="w-100"
                      unlink-panels
                      range-separator="-"
                      :start-placeholder="$t.translate('FRM_START_DATE')"
                      :end-placeholder="$t.translate('FRM_END_DATE')"
                      :shortcuts="$configs.datePickerShortcuts"
                      popper-class="cus-drange"
                    >
                    </el-date-picker>
                  </div>

                  <template v-if="!$h.hasRoles(['CLIENT', 'SUB_CLIENT'])">
                    <div class="col-lg-6">
                      <base-input
                        v-on:keyup.enter="filterAction"
                        v-model="Filter.Assessor"
                        :label="$t.translate('LBL_ASSESSOR')"
                        input-classes="form-control-alternative"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        v-on:keyup.enter="filterAction"
                        v-model="Filter.IndependentReviewer"
                        :label="$t.translate('LBL_INDEPENDENT_REVIEWER')"
                        input-classes="form-control-alternative"
                      />
                    </div>
                  </template>
                </div>
                <div class="row mb-2">
                  <div class="col-lg-6">
                    <el-button type="primary" @click="filterAction">
                      {{ $t.translate("BTN_SEARCH") }}
                    </el-button>
                    <el-button type="primary" class="ml-3" @click="resetParams">
                      {{ $t.translate("BTN_RESET") }}
                    </el-button>
                  </div>
                </div>
              </div>
            </card>
          </el-collapse-item>
        </el-collapse>

        <div class="row">
          <div class="col-xl-4 col-lg-6">
            <stats-card
              :title="$t.translate('TTL_TOTAL_CONTRACT_REVIEW')"
              type="gradient-green"
              :sub-title="$h.formatNumber(Counts.PENDING)"
              icon="fa fa-file"
              class="mb-4 mb-xl-1 shadow"
            >
            </stats-card>
          </div>
          <div class="col-xl-4 col-lg-6">
            <stats-card
              :title="$t.translate('TTL_TOTAL_UNDER_REVIEW')"
              type="gradient-orange"
              :sub-title="$h.formatNumber(Counts.UNDERREVIEW)"
              icon="fa fa-spinner"
              class="mb-4 mb-xl-1 shadow"
            >
            </stats-card>
          </div>
          <div class="col-xl-4 col-lg-6">
            <stats-card
              :title="$t.translate('LBL_COMPLETED')"
              type="gradient-info"
              :sub-title="$h.formatNumber(Counts.COMPLETED)"
              icon="fa fa-clipboard-check"
              class="mb-4 mb-xl-1 shadow"
            >
            </stats-card>
          </div>
        </div>

        <div class="shadow bg-white p-around mt-4">
          <div class="form-cont px-0">
            <div class="d-flex justify-content-between">
              <p
                ref="secProposal"
                class="sec-head td-px mb-2"
                style="text-transform: none"
              >
                {{ $t.translate("LBL_PENDING_APPLICATION") }}
              </p>
            </div>
          </div>
          <Table
            url="/application/list"
            :query="{ ...Filter }"
            ref="table"
            :default-sort="{ prop: Filter.SortBy, order: Filter.SortOrder }"
            @sort-change="sortChange"
            :viewData="handleEdit"
            class="gen-table-bd"
            height="46vh"
          >
            <el-table-column
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
              prop="ApplicationNo"
              :label="$t.translate('LBL_APPLICATION_NO')"
              min-width="150px"
              class-name="pl-1"
            >
              <template #default="scope">
                {{ scope.row.ApplicationNo }}
                <!-- <div
                  style="margin-top: 10px; font-size: 12px; color: red"
                  v-if="
                    scope.row.StartDateTime &&
                    scope.row.ApplicationStatusCode == 'INPROGRESS'
                  "
                >
                  This is shown for uat purposes, original cycle date:
                  {{ $h.formatDate(scope.row.StartDateTime) }} -
                  {{ $h.formatDate(scope.row.EndDateTime) }}
                </div> -->
              </template>
            </el-table-column>

            <el-table-column
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
              prop="AssessmentType"
              :label="$t.translate('FRM_ASSESSMENT_TYPE')"
              width="130px"
            >
              <template #header>
                <TableFilter
                  :handleSelection="
                    (arr) => $refs.table.currentSelection(arr, 'AssessmentType')
                  "
                  :hasSelection="true"
                  :fieldName="$t.translate('FRM_ASSESSMENT_TYPE')"
                  url="envconf/AccessmentTypeOpts"
                  fieldKey="value"
                  fieldLabel="label"
                />
              </template>

              <template #default="scope">
                {{ $t.translate(scope.row.AssessmentTypeLabel) }}
              </template>
            </el-table-column>

            <el-table-column
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
              prop="ServiceName"
              :label="$t.translate('LBL_ASSESSMENT_SERVICE')"
              width="150px"
            >
              <template #header>
                <TableFilter
                  :handleSelection="
                    (arr) => $refs.table.currentSelection(arr, 'AssessmentServiceId')
                  "
                  :hasSelection="true"
                  :fieldName="$t.translate('LBL_ASSESSMENT_SERVICE')"
                  url="/assessment/list"
                  fieldKey="AssessmentServiceId"
                  fieldLabel="ServiceCompleteName"
                  :fieldLang="true"
                />
              </template>

              <template #default="scope">
                {{ $h.formatName(scope.row, "ServiceName") }}
                <span v-if="$h.formatName(scope.row, 'ServiceNameExtra', true)">
                  - {{ $h.formatName(scope.row, "ServiceNameExtra") }}</span
                >
              </template>
            </el-table-column>

            <el-table-column
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
              prop="Applicant"
              :label="$t.translate('LBL_APPLICANT')"
              width="150px"
            >
              <template #default="scope">
                {{ $h.formatOrgBranch(scope.row) }}
              </template>
            </el-table-column>

            <el-table-column :label="$t.translate('LBL_PAYMENT_STATUS')" width="100px">
              <template #header>
                <TableFilter
                  :handleSelection="
                    (arr) => $refs.table.currentSelection(arr, 'PaymentType')
                  "
                  :hasSelection="true"
                  :fieldName="$t.translate('LBL_PAYMENT_STATUS')"
                  url="envconf/PaymentOpts"
                  fieldKey="value"
                  fieldLabel="label"
                />
              </template>

              <template #default="scope">
                <el-tag
                  :type="!scope.row.IsPaid ? 'warning' : 'success'"
                  effect="dark"
                  disable-transitions
                  >{{ $t.translate(scope.row.PaymentLabel) }}</el-tag
                >
              </template>
            </el-table-column>

            <el-table-column
              :label="$t.translate('LBL_PAYMENT_DATE')"
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
              prop="PaymentDateTime"
              width="140px"
            >
              <template #default="scope">
                {{ $h.formatDateTime(scope.row.PaymentDateTime) }}
              </template>
            </el-table-column>

            <template v-if="!$h.hasRoles(['CLIENT', 'SUB_CLIENT'])">
              <el-table-column :label="$t.translate('LBL_ASSESSOR')" width="140px">
                <template #default="scope">
                  {{ $h.getFullName(scope.row.AssessorUser, false, "--") }}
                </template>
              </el-table-column>

              <el-table-column
                :label="$t.translate('LBL_ASSESSOR_ON_SITE')"
                width="140px"
              >
                <template #default="scope">
                  {{ $h.getFullName(scope.row.AssessorOnSiteUser, false, "--") }}
                </template>
              </el-table-column>

              <el-table-column
                :label="$t.translate('LBL_INDEPENDENT_REVIEWER')"
                width="140px"
              >
                <template #default="scope">
                  {{ $h.getFullName(scope.row.IndepreviewerUser, false, "--") }}
                </template>
              </el-table-column>
            </template>

            <el-table-column
              :label="$t.translate('LBL_CREATED_DATE')"
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
              prop="LastCreated"
              width="140px"
            >
              <template #default="scope">
                {{ $h.formatDateTime(scope.row.LastCreated) }}
              </template>
            </el-table-column>

            <el-table-column
              :label="$t.translate('LBL_LAST_UPDATE')"
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
              prop="LastModified"
              width="140px"
            >
              <template #default="scope">
                {{ $h.formatDateTime(scope.row.LastModified) }}
              </template>
            </el-table-column>

            <el-table-column
              v-if="!$h.hasRoles(['CLIENT', 'SUB_CLIENT'])"
              :label="$t.translate('LBL_PROGESS')"
              :fixed="mq.current == 'xsmall' ? false : 'right'"
              width="330"
            >
              <template #header>
                <TableFilter
                  :handleSelection="
                    (arr) => $refs.table.currentSelection(arr, 'Progress')
                  "
                  :hasSelection="true"
                  :fieldName="$t.translate('LBL_PROGESS')"
                  url="envconf/ApplicationProgressOpts"
                  fieldKey="value"
                  fieldLabel="label"
                />
              </template>

              <template #default="scope">
                <ApplicationProgress :data="scope.row" />
              </template>
            </el-table-column>

            <el-table-column
              :label="$t.translate('LBL_STATUS')"
              :fixed="mq.current == 'xsmall' ? false : 'right'"
              width="132px"
            >
              <template #header>
                <TableFilter
                  :handleSelection="
                    (arr) => $refs.table.currentSelection(arr, 'ApplicationStatusCode')
                  "
                  :hasSelection="true"
                  :fieldName="$t.translate('LBL_STATUS')"
                  url="envconf/ApplicationStatusOpts"
                  fieldKey="value"
                  fieldLabel="label"
                />
              </template>

              <template #default="scope">
                <el-tag
                  :type="$configs.applicationStatusStyle[scope.row.ApplicationStatusCode]"
                  effect="dark"
                  disable-transitions
                  >{{ $t.translate(scope.row.ApplicationStatusName) }}</el-tag
                >
              </template>
            </el-table-column>
          </Table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApplicationProgress from "@/components/ApplicationProgress";
import { extendedConfigs } from "@/utils/configs";
import { reactive, onMounted, ref } from "vue";
import { get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import _ from "lodash";
import dayjs from "dayjs";
import Table from "@/components/Table.vue";
import TableFilter from "@/components/TableFilter.vue";
import { useMq } from "vue3-mq";
import { router } from "@/utils/router";

export default {
  components: {
    Table,
    TableFilter,
    ApplicationProgress,
  },
  setup() {
    let mq = useMq();
    let table = ref("table");

    let fieldsToSkip = ["SortBy", "SortOrder", "DatePeriodMode", "Dashboard"];
    let DatePeriod = [];
    let Filter = reactive({
      DatePeriod,

      Dashboard: 1,
      DatePeriodMode: "LastModified",
      SortBy: "LastModified",
      SortOrder: "descending",
    });

    let Counts = ref({});

    let filterAction = async () => {
      await table.value.handleCurrentChange(1, Filter);

      let obj = helpers.getPeriodMode({ ...Filter });
      delete obj.Dashboard;

      let res = await get("/application/count", obj);
      Counts.value = res?.Count || {};
    };

    const sortChange = ({ prop, order }) => {
      Filter.SortBy = prop;
      Filter.SortOrder = order;
      filterAction();
    };

    const handleEdit = (row, opt) => {
      helpers.handleViewApplication(router, row, opt);
    };

    onMounted(async () => {
      filterAction();
    });

    const resetParams = () => {
      let keys = Object.keys(Filter);
      for (let key of keys) {
        if (fieldsToSkip.indexOf(key) > -1) continue;
        Filter[key] = "";
      }

      Filter.DatePeriod = DatePeriod;
      filterAction();
    };

    return {
      resetParams,
      mq,
      Counts,
      table,
      handleEdit,
      filterAction,
      Filter,
      sortChange,
    };
  },
};
</script>
<style></style>
